import type { AuthType, User } from "~/types/general";
import type { BookingListResponse } from "~/types/api";

interface Params {
  onLogin: (...args: any) => void;
}

export const useAuth = (params?: Params) => {
  const { $pinia, $siteFetch } = useNuxtApp();
  const authStore = useAuthStore($pinia);

  const onAuth = async ({ token, type }: { token: string; type: AuthType }) => {
    authStore.authorize(type, token);

    if (type === "CUSTOMER") {
      return await $siteFetch<User>("/v1/customers/auth/me")
        .then((response) => {
          authStore.setUser(response);

          return true;
        })
        .catch((e) => {
          authStore.reset();

          return false;
        });
    }
    if (type === "BOOKING") {
      return await $siteFetch<BookingListResponse>("/v1/bookings")
        .then((response) => {
          authStore.setUser({
            uuid: response.data[0].uuid,
            name: response.data[0].code,
          });

          return true;
        })
        .catch((e) => {
          authStore.reset();

          return false;
        });
    }
  };

  if (params?.onLogin) {
    watch(
      () => authStore.isLoggedIn,
      (loggedIn) => {
        if (!loggedIn) return;
        params?.onLogin?.({ isCustomer: authStore.isCustomer });
      },
      {
        immediate: true,
      }
    );
  }

  return {
    onAuth,
  };
};
